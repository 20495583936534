<template>
  <div>
    <v-card-title>
      칼럼 {{ $route.params.uid ? '수정' : '등록' }}
      <v-btn v-if="$route.params.uid" class="ml-5" color="#ffd600" depressed rounded light @click="notification = true">
        발송하기
      </v-btn>
    </v-card-title>
    <v-row no-gutters class="mb-3">
      <v-card outlined width="320" class="mr-6">
        <v-card-actions>
          <v-btn text color="grey">발행 여부</v-btn>
          <v-spacer />
          <v-btn small depressed :text="status !== 'show'" :color="status !== 'show' ? null : '#ffd600'" :light="status === 'show'" @click="status = 'show'">
            바로 발행
          </v-btn>
          <v-btn
            small
            depressed
            :text="status !== 'pending'"
            :color="status !== 'pending' ? null : '#ffd600'"
            :light="status === 'pending'"
            @click="status = 'pending'"
          >
            발행 예약
          </v-btn>
          <v-btn small depressed :text="status !== 'keep'" :color="status !== 'keep' ? null : '#ffd600'" :light="status === 'keep'" @click="status = 'keep'">
            일단 보관
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card outlined width="400">
        <v-card-actions>
          <v-btn text color="grey">예약 시간</v-btn>
          <v-menu v-model="dateMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시작일"
                class="mx-3"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false" />
          </v-menu>
          <v-menu v-model="timeMenu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :disabled="status !== 'pending'"
                v-bind="attrs"
                outlined
                hide-details
                readonly
                dense
                placeholder="시간"
                v-on="date ? on : null"
              />
            </template>
            <v-time-picker v-model="time" ampm-in-title @click:minute="timeMenu = false" />
          </v-menu>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-card outlined>
      <v-row no-gutters>
        <v-col cols="5">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>작성멘토</v-list-item-title>
                <v-combobox v-model="req.mentorId" hide-details :items="listMentor" outlined dense />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>제목</v-list-item-title>
                <v-text-field v-model="req.title" outlined dense hide-details />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  라벨
                  <span class="ml-4" style="font-size: 13px">
                    라벨 1단계 값은 구분값으로, 2~3단계는 태그값으로 프론트에 노출됩니다.
                  </span>
                </v-list-item-title>
                <v-row no-gutters>
                  <v-col v-for="(e, i) in base" :key="i" cols="4">
                    <v-select
                      v-model="select[e.model]"
                      dense
                      hide-details
                      outlined
                      :prepend-inner-icon="`mdi-numeric-${i + 1}-box`"
                      :disabled="e.disabled ? !select[e.disabled] : null"
                      :items="stamp[e.model]"
                      :append-icon="null"
                      color="#ffd600"
                      clearable
                      :class="i != 0 ? 'ml-3' : ''"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  투명라벨
                  <span class="ml-4" style="font-size: 13px">
                    투명 라벨은 복수 선택 가능합니다.
                  </span>
                </v-list-item-title>
                <InvisibleStampInput v-model="req.invisibleStampList" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  썸네일 이미지
                  <span class="ml-4" style="font-size: 13px">
                    썸네일 이미지를 추가해주세요.
                  </span>
                </v-list-item-title>
                <v-card
                  v-if="req.header"
                  class="mr-3"
                  max-width="277px"
                  height="172px"
                  color="#ffd600"
                  rounded
                  flat
                  :style="`background: url(${req.header}) no-repeat; background-size: cover`"
                />
                <v-file-input v-model="header" outlined dense hide-details prepend-inner-icon="mdi-paperclip" :prepend-icon="null" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>멘토의 한마디</v-list-item-title>
                <v-textarea v-model="req.commentOfMentor" rows="1" no-resize outlined dense hide-details @change="(e) => writeText(e, 'commentOfMentor')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>칼럼 인트로 제목</v-list-item-title>
                <v-textarea v-model="req.subtitle" rows="1" no-resize outlined dense hide-details @change="(e) => writeText(e, 'subtitle')" />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>칼럼 인트로 문구</v-list-item-title>
                <v-textarea v-model="req.summary" outlined hide-details no-resize />
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  추천학력
                  <span class="ml-4" style="font-size: 13px">
                    추천학력을 등록하시면, 해당 학력의 유저에게 콘텐츠가 추천될 수 있습니다
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              전체
              <v-switch v-model="all" inset color="deep-purple accent-1" class="ml-4 mt-0" dense hide-details @change="changeAll" />
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12">
                    <v-card outlined class="pa-4">
                      <div v-for="(schoolKind, index) in req.schoolKinds" :key="index">
                        {{ index + 1 }}.
                        <span v-for="(item, i) in schoolKind" :key="i">
                          <span v-if="item">{{ i !== 'education' ? ' -' : '' }} {{ schoolKinds[typeof item === 'object' ? item[0] : item] }}</span>
                        </span>
                        <v-btn light class="ml-3 mb-1" rounded small color="#ffd600" @click="req.schoolKinds.splice(index, 1)" v-text="'삭제'" />
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <SchoolInput v-model="schoolKind" />
                  </v-col>
                  <v-col cols="2">
                    <v-btn color="#ffd600" light @click="pushSchoolList">
                      추가
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="7">
          <MarkdownEditor v-model="req.content" class="markdown my-5 mr-5" style="min-height: 700px" />
        </v-col>
      </v-row>
    </v-card>
    <div class="text-right">
      <v-btn color="#ffd600" light class="mt-3" @click="createColumn">{{ $route.params.uid ? '수정' : '추가' }}하기</v-btn>
    </div>
    <div class="text-center">
      <v-dialog v-model="notification" trasition="dialog-bottom-transition">
        <v-card>
          <v-card-title>칼럼 발송하기</v-card-title>
          <Notification v-if="$route.params.uid" type="column" :idOfType="$route.params.uid" />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, watch, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import Notification from '@/components/Notification.vue'
import InvisibleStampInput from '@/components/input/InvisibleStamp.vue'
import SchoolInput from '@/components/input/School.vue'

export default defineComponent({
  components: {
    MarkdownEditor,
    Notification,
    InvisibleStampInput,
    SchoolInput,
  },

  setup(props, { root }) {
    const state = reactive({
      all: true,
      notification: false,
      date: null,
      time: null,
      dateMenu: false,
      timeMenu: false,
      header: null,
      base: [{ model: 'firstDepth' }, { model: 'secondDepth', disabled: 'firstDepth' }, { model: 'thirdDepth', disabled: 'secondDepth' }],
      listMentor: [],
      status: 'keep',
      schoolKind: {
        education: null,
        kindList: [],
        yearList: [],
      },
      req: {
        title: null,
        content: null,
        stampList: [],
        invisibleStampList: [],
        subtitle: '',
        summary: '',
        commentOfMentor: '',
        mentorId: null,
        header: '',
        schoolKinds: [],
      },
      stamp: {
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
      },
      select: {
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
      },
      schoolKinds: {
        null: '전체',
        elementary: '초등학교',
        middle: '중학교',
        high: '고등학교',
        nAgain: 'N수',
        parents: '학부모',
        homeSchool: '검정고시',
        mentor: '멘토',
        other: '기타',
        general: '일반',
        autonomous: '자율',
        specialPurpose: '특목',
        specialized: '특성화',
        technical: '전문',
        science: '과학',
        international: '국제',
        art: '예술',
        foreign: '외국어',
        physical: '체육',
        alternative: '대안',
        etc: '기타',
        empty: 'NUL',
        nul: 'NUL',
        1: '1학년',
        2: '2학년',
        3: '3학년',
        4: '4학년',
        5: '5학년',
        6: '6학년',
      },
    })

    const getListMentor = async () => {
      const listMentor = await root.$store.dispatch('mentor/getListMentor', {
        offset: 1000,
      })
      state.listMentor = listMentor.map((mentor) => ({
        text: mentor.name,
        value: Number(mentor.id),
      }))
    }

    const selectNullDepth = async () => {
      try {
        const newStamp = await root.$store.dispatch('stamp/getNewStamp', {})

        newStamp.map((e) => {
          state.stamp.firstDepth.push(e.firstDepth)
        })
      } catch (err) {
        console.error(err)
      }
    }

    const selectFirstDepth = async () => {
      try {
        const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
          firstDepth: state.select.firstDepth,
        })

        state.stamp.secondDepth = []
        newStamp.map((e) => {
          if (e.secondDepth !== null) state.stamp.secondDepth.push(e.secondDepth)
          else state.req.stampList[0] = Number(e.id)
        })
      } catch (err) {
        console.error(err)
      }
    }

    const selectSecondDepth = async () => {
      try {
        const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
          firstDepth: state.select.firstDepth,
          secondDepth: state.select.secondDepth,
        })

        state.stamp.thirdDepth = []
        newStamp.map((e) => {
          if (e.thirdDepth !== null) state.stamp.thirdDepth.push(e.thirdDepth)
          else state.req.stampList[0] = Number(e.id)
        })
      } catch (err) {
        console.error(err)
      }
    }

    const selectThirdDepth = async () => {
      try {
        const newStamp = await root.$store.dispatch('stamp/getNewStamp', {
          firstDepth: state.select.firstDepth,
          secondDepth: state.select.secondDepth,
        })

        newStamp.map((e) => {
          if (e.thirdDepth === state.select.thirdDepth) state.req.stampList[0] = Number(e.id)
        })
      } catch (err) {
        console.error(err)
      }
    }

    const getColumn = async () => {
      try {
        const column = await root.$store.dispatch('column/getOneColumn', {
          id: Number(root.$route.params.uid),
        })
        console.log(column)

        state.req = {
          ...state.req,
          mentorId: {
            text: column.author.name,
            value: Number(column.author.id),
          },
          title: column.title,
          subtitle: column.subtitle,
          content: column.content,
          summary: column.summary,
          header: column.header,
          commentOfMentor: column.commentOfMentor,
          stampList: [Number(column.stamp[0]?.id)],
          invisibleStampList: column.invisibleStamp,
          schoolKinds: column.schoolList ?? [],
        }

        state.select.firstDepth = column.stamp[0]?.firstDepth
        await selectFirstDepth()
        state.select.secondDepth = column.stamp[0]?.secondDepth
        await selectSecondDepth()
        state.select.thirdDepth = column.stamp[0]?.thirdDepth
        await selectThirdDepth()

        if (column.schoolList[0]) state.all = false

        if (column.publishedAt) {
          const date = new Date(column.publishedAt)
          const Y = String(date.getFullYear())
          const M = String(date.getMonth() + 1).padStart(2, '0')
          const D = String(date.getDate()).padStart(2, '0')
          const hh = String(date.getHours()).padStart(2, '0')
          const mm = String(date.getMinutes()).padStart(2, '0')
          state.date = Y + '-' + M + '-' + D
          state.time = hh + ':' + mm

          if (new Date(column.publishedAt) > new Date()) {
            state.status = 'pending'
          } else {
            state.status = 'show'
          }
        } else {
          state.status = 'keep'
        }
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const updateColumn = async () => {
      if (!confirm('칼럼을 수정합니다.')) return

      try {
        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') {
          state.req.publishedAt = null
          state.date = null
          state.time = null
        } else state.req.publishedAt = date ?? new Date()

        await root.$store.dispatch('column/updateColumn', {
          ...state.req,
          mentorId: Number(state.req.mentorId.value),
          thumbnail: state.header ?? undefined,
          header: undefined,
          id: Number(root.$route.params.uid),
          invisibleStampList: state.req.invisibleStampList.map((x) => ({ name: x.name, visible: true })),
        })

        alert('칼럼이 정상적으로 수정되었습니다.')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const createColumn = async () => {
      try {
        if (root.$route.params.uid) return updateColumn()

        let date = null
        if (state.date && state.time) date = new Date(state.date + 'T' + state.time)
        else if (state.date) date = new Date(state.date + 'T00:00')

        if (state.status === 'keep') state.req.publishedAt = null
        else state.req.publishedAt = date ?? new Date()

        await root.$store.dispatch('column/createColumn', {
          ...state.req,
          stampList: [Number(state.req.stampList[0])],
          mentorId: Number(state.req.mentorId.value),
          fcmSend: false,
          header: state.header ?? undefined,
          invisibleStampList: state.req.invisibleStampList.map((x) => ({ name: x.name, visible: true })),
        })

        alert('칼럼이 정상적으로 추가되었습니다.!')
        root.$router.push('/column')
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const writeText = (text, field) => {
      let splitText = text.split('\n')
      if (splitText.length <= 1) return

      const fields = ['commentOfMentor', 'subtitle', 'summary']
      let index = fields.indexOf(field)

      while (splitText) {
        if (index + 1 === fields.length) {
          state.req[fields[index]] = splitText.join('\n')
          break
        } else state.req[fields[index++]] = splitText.shift()
      }
    }

    const changeAll = async () => {
      state.req.schoolKinds = []
    }

    const pushSchoolList = async () => {
      state.all = false
      state.req.schoolKinds.push(Object.assign({}, state.schoolKind))
      state.schoolKind = {
        education: null,
        kindList: null,
        yearList: null,
      }
    }

    onBeforeMount(() => {
      selectNullDepth()
      getListMentor()
      if (root.$route.params.uid) getColumn()
    })

    watch(
      () => state.select.firstDepth,
      () => {
        state.select.secondDepth = null
        state.select.thirdDepth = null
        if (state.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.select.secondDepth,
      () => {
        state.select.thirdDepth = null
        if (state.select.secondDepth) selectSecondDepth()
        else if (state.select.firstDepth) selectFirstDepth()
      },
    )

    watch(
      () => state.select.thirdDepth,
      () => {
        if (state.select.thirdDepth) selectThirdDepth()
        else if (state.select.secondDepth) selectSecondDepth()
        else if (state.select.firstDepth) selectFirstDepth()
      },
    )

    return {
      ...toRefs(state),
      writeText,
      createColumn,
      changeAll,
      pushSchoolList,
    }
  },
})
</script>
